<template>
  <!-- OVERLAY CONTAINER -->
  <div class="cart-overlay">
    <!-- HOOFD POPUP DISPLAY FLEX -->
    <div class="product-overlay-bg" style="display: flex">
      <div @click="$router.push('/')" class="product-overlay-close"></div>
      <div class="product-overlay-container">
        <close-button />
        <div id="product" class="view product" style="display: block">
          <div class="title-back">
            <div
              v-if="state.categoryEnabled"
              class="cart-go-back"
              @click="back()"
            >
              <svg
                alt="back"
                width="9"
                height="15"
                viewBox="0 0 9 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.921875 7.40625C0.707031 7.62109 0.707031 7.92187 0.921875 8.13672L7.23828 14.4961C7.41016 14.7109 7.75391 14.7109 7.96875 14.4961L8.78516 13.6367C9 13.4648 9 13.1211 8.78516 12.9062L3.71484 7.79297L8.78516 2.63672C9 2.42188 9 2.12109 8.78516 1.90625L7.96875 1.04687C7.75391 0.832031 7.41016 0.832031 7.23828 1.04687L0.921875 7.40625Z"
                  fill="black"
                />
              </svg>
            </div>
            <div class="container-heading">
              {{ product?.categorie }} - {{ product?.name }}
            </div>
          </div>
          <div class="product-block">
            <div class="product-detail-img">
              <img
                :src="state.selectedVariant?.image"
                loading="lazy"
                sizes="100vw"
                alt=""
              />
            </div>
            <div class="product-detail-description">
              <div class="product-item-row">
                <div class="product-small-heading">Artikelnummer</div>
                <div>{{ state.selectedVariant?.sku }}</div>
              </div>
              <div class="product-item-row">
                <div class="product-small-heading">Omschrijving</div>
                <div>
                  {{
                    state.selectedVariant?.description ?? product.description
                  }}
                </div>
              </div>
              <div class="w-form">
                <form
                  id="wf-form-add-to-cart"
                  name="wf-form-add-to-cart"
                  data-name="add-to-cart"
                  v-on:submit.prevent="addToCart"
                >
                  <label for="vorm" class="cart-label">Vorm</label>
                  <select
                    v-if="state.shapes?.length > 1"
                    name="shape"
                    v-model="selected.shape"
                    class="cart-select w-select"
                    @change="shapeChanged"
                  >
                    <option
                      v-for="item in state.shapes"
                      :key="item.shape"
                      :value="item.shape"
                    >
                      {{ item.shape }}
                    </option>
                  </select>
                  <div
                    v-else-if="state.shapes?.length > 0"
                    class="empty-select"
                  >
                    {{ state.shapes[0].shape ?? "" }}
                  </div>
                  <label for="maat" class="cart-label">Formaat</label>
                  <select
                    v-if="state.sizes.length > 1"
                    name="size"
                    v-model="selected.size"
                    class="cart-select w-select"
                  >
                    <option
                      v-for="item in state.sizes"
                      :key="item.size"
                      :value="item.size"
                    >
                      {{ item.size }} {{ item?.sizeDescription }} -
                      {{ priceFormater(item.price) }}
                    </option>
                  </select>
                  <div v-else-if="state.sizes.length > 0" class="empty-select">
                    {{ state.sizes[0].sizeDescription }} -
                    {{ priceFormater(state.sizes[0].price) }}
                  </div>
                  <label for="extra-opties" class="cart-label">Extra</label>
                  <select
                    name="extra"
                    v-model="state.selectedExtra"
                    class="cart-select w-select"
                  >
                    <option
                      v-for="item in extraValues"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <div v-if="selectedExtraObject?.color">
                    <label for="custom-text0" class="cart-label"
                      >Kleur rouwlint</label
                    >
                    <input
                      v-model="state.customColor"
                      type="text"
                      class="cart-input w-input"
                      :maxlength="30"
                      :id="'custom-color'"
                      :required="true"
                    />
                  </div>
                  <div v-if="selectedExtraObject?.extra">
                    <label for="custom-text0" class="cart-label"
                      >Uw tekst</label
                    >
                    <div
                      v-for="(item, key) in selectedExtraObject?.chars"
                      :key="key"
                    >
                      <input
                        v-model="state.customText[key]"
                        v-if="item < 100"
                        type="text"
                        class="cart-input w-input"
                        :maxlength="item"
                        :placeholder="
                          placeHolder(key, selectedExtraObject?.chars)
                        "
                        :id="'custom-text' + key"
                        :required="key == 0"
                      />
                      <textarea
                        v-model="state.customText[key]"
                        v-else
                        rows="2"
                        class="cart-input w-input"
                        :id="'custom-text' + key"
                        :maxlength="item"
                        :placeholder="
                          placeHolder(key, selectedExtraObject?.chars)
                        "
                      ></textarea>
                    </div>
                  </div>

                  <!-- rouwlint  2 tekstvelden 50 chars.
                        Standaardkaart, graag op max 200 tekens zetten.
                        Luxe kaart, graag op max 500 tekens zetten.  -->

                  <label for="aantal" class="cart-label">Aantal</label>
                  <select
                    v-model="state.amount"
                    name="amount"
                    class="cart-select w-select"
                  >
                    <option
                      v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <input
                    type="submit"
                    value="Product toevoegen"
                    data-wait="Moment geduld..."
                    class="cart-submit w-button"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton /* webpackChunkName: "v" */ from "@/components/closeButton";
import { computed, reactive, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { useCart } from "@/services/Cart";
export default {
  name: "ProductItem",
  // eslint-disable-next-line vue/no-unused-components
  components: { CloseButton },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const cart = useCart();
    const state = reactive({
      shapes: [],
      sizes: [],
      selectedVariant: {},
      selectedExtra: "Geen",
      customText: ["", ""],
      customColor: null,
      amount: 1,
      extraValues: [],
    });
    const selectedExtraObject = computed(function () {
      return extra.find((item) => item.label === state.selectedExtra);
    });
    const product = computed(function () {
      let item =
        store.state.products.find((item) => item.sku === route.params.sku) ??
        store.state.products.find(function (item) {
          if (
            item.variant.find(
              (item) =>
                item.sku === route.params.sku ||
                item.skuParent === route.params.sku
            )
          ) {
            state.selectedVariant = item.variant.find(
              (item) =>
                item.sku === route.params.sku ||
                item.skuParent === route.params.sku
            );
            return true;
          }
          return false;
        });
      return (
        item ?? {
          name: "niet gevonden",
          image: null,
          description: "",
          sku: "",
          variant: [],
        }
      );
    });
    const selected = reactive({
      size: false,
      shape: false,
    });

    function setVariant(newProductValue) {
      state.shapes = newProductValue.variant?.filter(function (item) {
        return (
          newProductValue.variant.find(
            (variantItem) => variantItem.shape === item.shape
          ) === item
        );
      });
      state.sizes = newProductValue.variant?.filter(function (item) {
        return (
          newProductValue.variant.find(
            (variantItem) =>
              (state.selectedVariant?.shape === false ||
                variantItem?.shape === state.selectedVariant?.shape) &&
              variantItem?.size === item.size
            // && variantItem?.sizeDescription === item.sizeDescription
          ) === item
        );
      });
      if (
        newProductValue?.variant.find((item) => item === state.selectedVariant)
      ) {
        selected.shape = state.selectedVariant.shape;
        selected.size = state.selectedVariant.size;
        return;
      }
      state.selectedVariant = findSelectedVariant(newProductValue);
      selected.shape = state.selectedVariant.shape;
      selected.size = state.selectedVariant.size;
    }

    watch(product, setVariant, { deep: true });
    watch(
      selected,
      function () {
        state.selectedVariant = findSelectedVariant(product.value);
      },
      { deep: true }
    );

    const extra = [
      {
        value: "Geen",
        label: "Geen",
        price: 0,
        extra: false,
      },
      {
        value: "kaart",
        label: "Standaardkaart - €1,60",
        price: 1.6,
        extra: true,
        chars: [200],
      },
      {
        value: "Luxe kaart",
        label: "Luxe kaart - €3,60",
        price: 3.6,
        extra: true,
        chars: [500],
      },
      {
        value: "Lint",
        label: "Rouwlint - €17,50",
        price: 17.5,
        extra: true,
        color: true,
        chars: [50, 50],
      },
    ];

    const extraValues = extra.map((item) => item.label);
    // eslint-disable-next-line no-unused-vars
    function findSelectedVariant(newProductValue) {
      const product =
        newProductValue.variant?.find(function (item) {
          return (
            (selected.size === false || item.size === selected.size) &&
            (selected.shape === false || item.shape === selected.shape)
          );
        }) ??
        newProductValue.variant?.find(function (item) {
          return selected.shape === false || item.shape === selected.shape;
        }) ??
        newProductValue.variant[0];
      router.replace({
        name: "product",
        params: { sku: product.sku },
      });
      return product;
    }
    store.getters.getData;

    if (product.value.sku) {
      setVariant(product.value);
    }

    function back() {
      if (
        String(router.options.history.state.back) === "/" ||
        String(router.options.history.state.back) === ""
      ) {
        return router.push({
          name: "category",
          params: { name: product.value.categorie },
        });
      }
      router.back();
    }

    function shapeChanged() {
      //set sizes
      state.sizes = product.value.variant?.filter(function (item) {
        return (
          product.value.variant.find(
            (variantItem) =>
              (selected?.shape === false ||
                variantItem?.shape === selected?.shape) &&
              variantItem?.size === item.size &&
              variantItem?.sizeDescription === item.sizeDescription
          ) === item
        );
      });
    }

    function placeHolder(key, charsArray) {
      if (key === 0 && charsArray.length === 1) {
        return "Uw text (max: " + charsArray[key] + " characters) ";
      }
      return key + 1 + "de regel (max: " + charsArray[key] + " characters) ";
    }

    const price = computed(function () {
      return (
        (state.selectedVariant.price + selectedExtraObject.value.price) *
        state.amount
      );
    });

    const priceFormat = computed(() => priceFormater(price.value));

    function priceFormater(value) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }).format(value);
    }

    function addToCart() {
      const customText = [];
      if (selectedExtraObject.value?.chars?.length) {
        for (const key in selectedExtraObject.value?.chars) {
          customText.push(state.customText[key]);
        }
      }
      cart.addCartItem({
        ...state.selectedVariant,
        amount: state.amount,
        extra: selectedExtraObject.value,
        customText: customText,
        customColor: state.customColor,
      });
      router.push({ name: "cart" });
      return false;
    }

    state.categoryEnabled = computed(function () {
      return process.env.VUE_APP_CATEGORY_ENABLED === "true";
    });

    return {
      product,
      state,
      back,
      extra,
      extraValues,
      selected,
      selectedExtraObject,
      placeHolder,
      price,
      priceFormat,
      priceFormater,
      addToCart,
      shapeChanged,
    };
  },
};
</script>

<style>
.empty-select {
  padding-bottom: 15px;
}
</style>
